<div class="name notranslate">{{ event.name }}</div>
<div class="organisator notranslate">{{ event.organisator }}</div>
<div class="date" [class.passed]="isPassed">{{ event.date | date : 'mediumDate' }}</div>
<div class="time">{{ event.date | date : 'shortTime' }}</div>
<div class="location notranslate">{{ event.location }}</div>

<div class="image"
    [style.backgroundImage]="'url(' + image + ')'"
    [style.backgroundPosition]="event.imagePosition"></div>

@if(!isPassed && event.limit && event.limit > 0) {
    <div
        class="limit"
        [class.full]="isFull">

        @if(isFull) {
            {{ 'inscription.full' | translate }}
        } @else {
            {{ 'inscription.left' | translate: { count: event.limit - (event.members || []).length } }}
        }
    </div>
}

@if (isPassed) {
    <div class="limit passed">
        {{ 'inscription.outdated' | translate }}
    </div>
}

<div class="members">
    @for(member of event.members; track member; let index = $index) {

        @let isCurrentMember = member.email === email;

        <div class="member"
            [wuiScrollOptions]="{ block: 'center', behavior: 'smooth' }"
            [wuiScroll]="isCurrentMember && isJustJoigned"
            [class.current-member]="isCurrentMember">
            {{ index + 1 | number:'2.0' }}. {{ member.pseudo }} ({{ member.group || 'freelance' }})
        </div>
    }
</div>

<div class="description"
    [innerHtml]="paragraphes.output | markdown">
</div>

@if (isParagraphesReading) {
    <div class="reading">

        <div class="please">
            {{ 'inscription.readToContinue' | translate }}
        </div>

        <div class="loading"
            [class.loadingAnimation]="isContinueAnimated">
        </div>

        <div
            class="continue"
            [class.continueAnimation]="isContinueAnimated"
            (click)="paragraphesNext()">
            {{ 'shared.continue' | translate }}
            <br/>
            ᐁ
        </div>
    </div>
}

@if (!isFull && !isPassed && !isParagraphesReading) {

    <div class="buttons">
        <!-- <input type="button" class="group" (click)="join()" [value]="'inscription.join.' + (isJustJoigned ? 'another' : 'self') | translate"> -->
        <input type="button" class="group" (click)="join()" [value]="'inscription.join.self' | translate">
    </div>

}

<a class="remix"
    (click)="remix()"
    [title]="'inscription.remix.tooltip' | translate">
    {{ 'inscription.remix.title' | translate }}
</a>

