import { Component, HostListener, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OnlineService } from '@kireidy/services';
import { OverlayService, StringUtils } from '@kireidy/wild-ui';
import { SubscribeMemberRequest, XEvent } from '@kireidy/definitions';

import { EmailRegExp, PhoneNumberInternationalRegExp, PhoneNumberNationalRegExp } from '../../../shared/constants/regexp.constants';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'kireidy-inscription-modal',
    templateUrl: './inscription-modal.component.html',
    styleUrls: ['./inscription-modal.component.scss'],
})
export class InscriptionModalComponent implements OnInit {

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: boolean | null = null) => { /* */ };

    @Input()
    public event: XEvent = new XEvent;

    public request = new SubscribeMemberRequest();

    public emailConfirmation = '';

    public invalidity = '';

    public isLoading = false;

    constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    public ngOnInit(): void {

        this.request.eventId = this.event.id;

        for(const question of this.event.questions) {
            this.request.responses.push({
                name: question.name,
                value: question.choices.length ? question.choices[0] : ''
            });
        }

        this.checkValidity();
    }

    @HostListener('document:keyup.escape', ['$event'])
    public cancel(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

    @HostListener('document:keyup.enter', ['$event'])
    public confirm(): void {

        this.isLoading = true;

        this.onlineService
            .subscribeMember(this.event.id, this.request)
            .pipe(finalize(() => { this.isLoading = false; })
            )
            .subscribe({
                next: (response) => {

                    // Update the members list (reference as input)
                    this.event.members = response.members;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('inscription.notifications.success'),
                        type: 'success'
                    });

                    localStorage.setItem('email', this.request.email);

                    this.resolve(true);

                    this.overlayService.closeModal();
                },
                error: (response) => {
                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('inscription.notifications.error', { message: response.error.message }),
                        type: 'error'
                    });
                }
            });
    }

    public setResponse(name: string, value: string): void {

        const response = this.request.responses
            .find(response => response.name === name);

        if(response) {
            response.value = value;
        }
    }

    public checkValidity() {

        this.invalidity = '';

        // Format first
        this.request.pseudo = StringUtils.capitalize(this.request.pseudo).trim();
        this.request.email = this.request.email.toLowerCase();
        this.emailConfirmation = this.emailConfirmation.toLowerCase();

        // Then check
        if (this.request.pseudo.length < 3) {
            this.invalidity = this.translateService.instant('inscription.invalidities.missingPseudo');

            return;
        }

        if (this.event.members.some(member => member.pseudo.toUpperCase() === this.request.pseudo.trim().toUpperCase())) {
            this.invalidity = this.translateService.instant('inscription.invalidities.unavailablePseudo');

            return;
        }

        if (!this.event.isSimplifiedInscription) {

            // Format first
            this.request.firstName = StringUtils.capitalize(this.request.firstName || '').trim();
            this.request.lastName = StringUtils.capitalize(this.request.lastName || '').trim();

            // Then check
            if (this.request.firstName.length < 3) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingFirstName');

                return;
            }

            if (this.request.lastName.length < 3) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingLastName');

                return;
            }

            if (!this.request.phone) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingPhone');

                return;
            }

            if (!PhoneNumberInternationalRegExp.test(this.request.phone) && !PhoneNumberNationalRegExp.test(this.request.phone)) {
                this.invalidity = this.translateService.instant('inscription.invalidities.invalidPhone');

                return;
            }

            if (!this.request.dob) {
                this.invalidity = this.translateService.instant('inscription.invalidities.missingDob');

                return;
            }

            const   dateEighteenAgo = new Date();
                    dateEighteenAgo.setFullYear(new Date().getFullYear() - environment.inscriptions.minimalAge);

            if (new Date(this.request.dob).getTime() > dateEighteenAgo.getTime()) {
                this.invalidity = this.translateService.instant('inscription.invalidities.invalidDob');

                return;
            }
        }

        if (!this.request.email) {
            this.invalidity = this.translateService.instant('inscription.invalidities.missingEmail');

            return;
        }

        if (!EmailRegExp.test(this.request.email)) {
            this.invalidity = this.translateService.instant('inscription.invalidities.invalidEmail');

            return;
        }

        if (this.request.email !== this.emailConfirmation) {
            this.invalidity = this.translateService.instant('inscription.invalidities.mismatchingEmail');

            return;
        }

    }
}
