import { Component, OnInit } from '@angular/core';

import { Host } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';
import { OverlayService } from '@kireidy/wild-ui';

import { ModalCookiesLearnMoreComponent } from '../modal-cookies-learn-more/modal-cookies-learn-more.component';

@Component({
    selector: 'kireidy-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public year: number = (new Date()).getFullYear();

    public host?: Host;

    constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService) {}

    public ngOnInit(): void {

        this.onlineService.getHost().subscribe({
            next: (host: Host) => {
                this.host = host;
            }
        });
    }

    public learnMoreCookies(): void {
        this.overlayService.openModal({
            component: ModalCookiesLearnMoreComponent,
            inputs: {
                isProposalDisplayed: false
            }
        });
    }

}
