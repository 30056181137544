<wui-modal-layout>

    <div title>{{ 'cookies.title' | translate }}</div>

    <div body>

        <wui-section [name]="'cookies.learnMore.introduction.title' | translate">
            {{ 'cookies.learnMore.introduction.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.functional.title' | translate">
            {{ 'cookies.learnMore.functional.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.statistics.title' | translate">
            {{ 'cookies.learnMore.statistics.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.commercial.title' | translate">
            {{ 'cookies.learnMore.commercial.description' | translate }}
        </wui-section>

    </div>

    <div footer class="footer">

        @if(isProposalDisplayed) {
            <kireidy-icon-button
                class="cancel"
                (click)="decline()">
                ❌
            </kireidy-icon-button>
        }

        <kireidy-icon-button
            class="confirm"
            (click)="accept()">
            ✅
        </kireidy-icon-button>

    </div>

</wui-modal-layout>
