export class StringUtils {

    public static capitalize(value: string): string {

        // Todo, manage case like ¡spannish!
        // Todo, manage multiquotes texts!

        if (!value) {
            return '';
        }

        return value[0].toUpperCase() + value.slice(1);
    }

    public static uncapitalize(value: string): string {

        // Usefull for SomeThing -> someThing

        if (!value) {
            return '';
        }

        return value[0].toLowerCase() + value.slice(1);
    }

    public static camelCaseToPlain(value: string): string {

        // HelloWorld -> hello world

        return value
            .replace(/([A-Z]+)/g, ' $1')
            .replace(/([A-Z][a-z])/g, ' $1');
    }

    public static toKebabCase(value: string): string {

        // Hello world -> hello-world

        return value
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/[\s_]+/g, '-')
            .toLowerCase();
    }

}
