<kireidy-modal-layout>

    <div body>

        <p>
            {{ description }}
        </p>

        <kireidy-entry class="reason">
            <div title>{{ 'edition.unsubscription.reason.title' | translate }} *</div>
            <div detail>{{ 'edition.unsubscription.reason.detail' | translate }}</div>
            <ng-container value>
                <textarea
                    [(ngModel)]="result.reason">
                </textarea>
            </ng-container>
        </kireidy-entry>

    </div>

    <div class="footer" footer>

        <kireidy-icon-button
            class="cancel"
            (click)="cancel()">
            ❌
        </kireidy-icon-button>

        <kireidy-icon-button
            class="confirm"
            (click)="confirm()">
            ✅
        </kireidy-icon-button>

    </div>

</kireidy-modal-layout>
