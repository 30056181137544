import { Component, ElementRef, OnInit } from '@angular/core';

import { OverlayService } from '@kireidy/wild-ui';

import { ModalCookiesLearnMoreComponent } from '../modal-cookies-learn-more/modal-cookies-learn-more.component';

const STORAGE_NAME = 'cookies-settings';
const STORAGE_VERSION = 1;

@Component({
    selector: 'kireidy-cookies-wall',
    templateUrl: './cookies-wall.component.html',
    styleUrls: ['./cookies-wall.component.scss'],
})
export class CookiesWallComponent implements OnInit {

    constructor(
        private elementReference: ElementRef,
        private overlayService: OverlayService) { }

    public ngOnInit(): void {

        if (localStorage.getItem(STORAGE_NAME) !== null) {
            this.elementReference.nativeElement.style.display = 'none';
        }
    }

    public async learnMore(): Promise<void> {

        const isConfirmed = await this.overlayService.openModal({
            component: ModalCookiesLearnMoreComponent,
            inputs: {
                isProposalDisplayed: true
            }
        });

        if (isConfirmed) {
            this.accept();
        }
    }

    public accept(): void {

        localStorage.setItem(STORAGE_NAME, JSON.stringify({ version: STORAGE_VERSION, isAccepted: true }));

        this.elementReference.nativeElement.style.display = 'none';

        // Reload the page for HotJar
        // eslint-disable-next-line no-self-assign
        window.location = window.location;
    }

}
