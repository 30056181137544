<div class="banner">

    <div class="message">
        {{ 'cookies.banner.message' | translate }}
    </div>

    <wui-button
        class="learnMore"
        (click)="learnMore()">
        {{ 'shared.learnMore' | translate }}
    </wui-button>

    <wui-button
        class="accept"
        (click)="accept()">
        {{ 'shared.accept' | translate }}
    </wui-button>

</div>
