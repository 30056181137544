<kireidy-modal-layout>

    <div title>{{ 'inscription.title' | translate: { event } }}</div>

    <div class="body" body>

        <kireidy-entry>
            <div title>{{ 'inscription.pseudo.title' | translate }} *</div>
            <div detail>{{ 'inscription.pseudo.detail' | translate }}</div>
            <input
                value
                type="text"
                [(ngModel)]="request.pseudo"
                (input)="checkValidity()">
        </kireidy-entry>

        <kireidy-entry>
            <div title>{{ 'inscription.group.title' | translate }}</div>
            <div detail>{{ 'inscription.group.detail' | translate }}</div>
            <input
                value
                type="text"
                [(ngModel)]="request.group"
                (input)="checkValidity()">
        </kireidy-entry>

        @if(event.isSimplifiedInscription === false) {

            <kireidy-entry>
                <div title>{{ 'inscription.firstName.title' | translate }} *</div>
                <div detail>{{ 'inscription.firstName.detail' | translate }}</div>
                <input
                    value
                    type="text"
                    name="firstName"
                    [(ngModel)]="request.firstName"
                    (input)="checkValidity()">
            </kireidy-entry>

            <kireidy-entry>
                <div title>{{ 'inscription.lastName.title' | translate }} *</div>
                <div detail>{{ 'inscription.lastName.detail' | translate }}</div>
                <input
                    value
                    type="text"
                    name="lastName"
                    [(ngModel)]="request.lastName"
                    (input)="checkValidity()">
            </kireidy-entry>


            <kireidy-entry>
                <div title>{{ 'inscription.phone.title' | translate }} *</div>
                <div detail>{{ 'inscription.phone.detail' | translate }}</div>
                <input
                    value
                    type="text"
                    [(ngModel)]="request.phone"
                    (input)="checkValidity()">
            </kireidy-entry>

            <kireidy-entry>
                <div title>{{ 'inscription.dob.title' | translate }} *</div>
                <div detail>{{ 'inscription.dob.detail' | translate }}</div>
                <input
                    value
                    type="date"
                    [(ngModel)]="request.dob"
                    (input)="checkValidity()">
            </kireidy-entry>

        }

        @for(question of event.questions; track question) {
            <kireidy-entry>
                <div title>{{ question.name | capitalize }}</div>
                    @if(question.choices.length) {
                        <select value #responseSelect (change)="setResponse(question.name, responseSelect.value)">
                            @for(choice of question.choices; track choice) {
                                <option>{{ choice }}</option>
                            }
                        </select>

                    } @else {
                        <input value #responseText type="text" maxlength="40" (change)="setResponse(question.name, responseText.value)">
                    }
            </kireidy-entry>
        }

        <kireidy-entry>
            <div title>{{ 'inscription.email.title' | translate }} *</div>
            <div detail>{{ 'inscription.email.detail' | translate }}</div>
            <input
                value
                type="email"
                name="email"
                [(ngModel)]="request.email"
                (input)="checkValidity()">
        </kireidy-entry>

        <kireidy-entry>
            <div title>{{ 'inscription.emailConfirmation.title' | translate }} *</div>
            <div detail>{{ 'inscription.emailConfirmation.detail' | translate }}</div>
            <input
                value
                type="email"
                name="email"
                [(ngModel)]="emailConfirmation"
                (input)="checkValidity()">
        </kireidy-entry>

        <div class="invalidity" [class.warning]="!invalidity">
            {{ invalidity }}
        </div>

    </div>

    <div class="footer" footer>

        <kireidy-icon-button
            class="cancel"
            (click)="cancel()">
            ❌
        </kireidy-icon-button>

        <kireidy-icon-button
            class="confirm"
            [class.disabled]="isLoading || invalidity"
            [class.pulsing-fast]="isLoading"
            (click)="confirm()">
            ✅
        </kireidy-icon-button>

    </div>

</kireidy-modal-layout>
