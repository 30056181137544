import { Member } from './member.entity';

export class XEvent {

    public id?: any;

    public name = '';

    public description = '';

    public organisator = '';

    public location = '';

    public imagePosition = '';

    public date = new Date((new Date().setHours(9, 0, 0) + 24 * 60 * 60 * 1000 ));  // TOMOROW 9:00

    public limit = 0;

    public members = new Array<Member>();

    public email = '';

    public questions = new Array<XEventQuestion>();

    public key = '';

    public isSimplifiedInscription = false;

    public isTimedReading = false;

}

export class XEventImage {

    public eventId!: string;

    public data!: Buffer;

    public type!: string;
}

export class XEventQuestion {

    public name = '';

    public choices = new Array<string>();

    public pattern = '';
}

export class XEventResponse {

    public name = '';

    public value = '';
}
