import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { OnlineService } from '@kireidy/services';
import { OverlayService, StringUtils } from '@kireidy/wild-ui';

import { CreateXEventRequest, CreateXEventResponse, XEvent, UnsubscribeMemberRequest, UnsubscribeMemberResponse, Member, UpdateXEventRequest, DeleteXEventResponse } from '@kireidy/definitions';
import { environment } from '../../../../environments/environment';
import { EmailRegExp } from '../../../shared/constants/regexp.constants';
import { FilesHelpers } from '../../../shared/helpers/files/files.helpers';

import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';
import { EditionUnsubscriptionModalComponent } from '../edition-unsubscription-modal/edition-unsubscription-modal.component';


@Component({
    selector: 'kireidy-edition',
    templateUrl: './edition.component.html',
    styleUrls: ['./edition.component.scss']
})

export class EditionComponent implements OnInit {

    public isLoading = false;
    public isUrlCopied = false;
    public isPreviewingDescription = false;
    public isPreviewingQuestions = false;
    public isKPlusAdministrator = false;
    public invalidity: string | null = null;

    public event = new XEvent();
    public questions = '';

    private newImage: string | undefined;
    private updatedImage: string | undefined;

    constructor(
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        const eventId = this.route.snapshot.paramMap.get('eventId');

        if (!eventId) {

            this.title.setTitle(this.translateService.instant('shared.creation'));

            // Remix feature
            const from = this.route.snapshot.queryParamMap.get('from');

            if (from) {

                this.onlineService
                    .getEvent(from)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        })
                    )
                    .subscribe({
                        next: (response) => {

                            const {
                                name,
                                description,
                                // questions,
                                isSimplifiedInscription,
                                isTimedReading
                            } = response;

                            this.event = Object.assign(this.event, {
                                name: `${name} (remix)`,
                                description,
                                isSimplifiedInscription,
                                isTimedReading
                            });

                            // this.loadQuestions(questions);  // Be careful, this is a K+ option

                            this.overlayService.openNotification({
                                message: this.translateService.instant('edition.notifications.remix', { name }),
                                type: 'success'
                            });

                            this.checkValidity();
                        }
                    });

                return;
            }

            this.checkValidity();

            return;
        }

        this.title.setTitle(this.translateService.instant('shared.edition'));

        const key = this.route.snapshot.paramMap.get('key') || '';

        this.onlineService
            .getEvent(eventId, key)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe({
                next: (response) => {

                    this.event = response;

                    this.title.setTitle(this.event.name);

                    this.loadEditableQuestions(this.event);

                    // For old events without these properties
                    this.event.isSimplifiedInscription = this.event.isSimplifiedInscription !== false;
                    this.event.isTimedReading = this.event.isTimedReading === true;

                    this.checkValidity();
                },
                error: () => {

                    this.overlayService.openNotification({
                        message: this.translateService.instant('event.notFound'),
                        type: 'error'
                    });

                    this.router.navigateByUrl('/');
                }
            });
    }

    public get isIncomplete(): boolean {

        return !this.event.name ||
            !this.event.location ||
            (this.event.date.getTime() < Date.now()) ||
            !this.event.email;
    }

    public checkValidity(): void {

        // TODO: Temporary code, use a K+ email list instead
        this.isKPlusAdministrator = [
            'mrgn.development@gmail.com',
            'samihassad84@gmail.com',
            'wtunit.84@gmail.com',
            'peloponnese.evenement.orga@gmail.com',
            'guyot.nathan@gmail.com'
        ].indexOf(this.event.email) >= 0;

        this.invalidity = '';

        // Format first
        this.event.name = StringUtils.capitalize(this.event.name).trim();
        this.event.organisator = StringUtils.capitalize(this.event.organisator).trim();
        this.event.location = StringUtils.capitalize(this.event.location).trim();
        this.event.email = this.event.email.toLowerCase();

        // Then check
        if (!this.event.name) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingName');

            return;
        }

        if (!this.event.organisator) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingOrganisator');

            return;
        }

        if (!this.event.location) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingLocation');

            return;
        }

        if (this.event.date.getFullYear() === 0) {
            this.invalidity = this.translateService.instant('edition.invalidities.missingDate');

            return;
        }

        if (this.event.date.getTime() < Date.now()) {
            this.invalidity = this.translateService.instant('edition.invalidities.outdated');

            return;
        }

        if (!EmailRegExp.test(this.event.email)) {
            this.invalidity = this.translateService.instant('edition.invalidities.invalidAdministratorEmail');

            return;
        }
    }

    public get image(): any {

        return this.newImage || this.updatedImage || (this.event.id ? `${this.onlineService.backendUrl}/events/${this.event.id}/image` : ``);
    }

    public updateDate(value: string): void {

        if (value === '') {

            this.event.date.setFullYear(0);

            return;
        }

        const fields: Array<number> = value.split('-').map(field => parseInt(field, 10));

        this.event.date.setFullYear(fields[0], fields[1] - 1, fields[2]);
    }

    public updateTime(value: string): void {

        if (value === '') {

            this.event.date.setHours(0, 0, 0);

            return;
        }

        const fields: Array<number> = value.split(':').map(field => parseInt(field, 10));

        this.event.date.setHours(fields[0], fields[1]);
    }

    public async loadImage(): Promise<void> {

        const file = await FilesHelpers.loadAsDataURL('image/*');

        const image:  HTMLImageElement = document.createElement('img');

        image.onload = () => {

            const ratios = {
                with: image.width / environment.images.maximumSize,
                height: image.height / environment.images.maximumSize,
                biggest: 0
            };

            ratios.biggest = (ratios.with > ratios.height ? ratios.with : ratios.height);

            console.log(ratios.biggest);

            const canvas: HTMLCanvasElement = document.createElement('canvas');

            canvas.width = image.width / ratios.biggest;
            canvas.height = image.height / ratios.biggest;

            const context = canvas.getContext('2d');

            if (!context) {

                return;
            }

            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            this.newImage = canvas.toDataURL('image/*') as any;
        }

        image.src = file as any;
    }

    public setImagePosition(position: string): void {

        this.event.imagePosition = position;
    }

    public loadEditableQuestions(event: XEvent): void {

        this.questions = '';

        for(const question of event.questions) {
            this.questions += `${question.name} ? ${question.choices.join(' : ')}\n`;
        }
    }

    public updateQuestions(value: string): void {

        const questionLines = value
            .split('\n')
            .filter(line => line);

        const questions = [];

        for(const question of questionLines) {

            if (question.indexOf('?') < 0) {
                return;
            }

            const name = StringUtils.capitalize(question.split('?')[0]).trim();

            let choices = question
                .split('?')[1]
                .split(':')
                .map(value => value.trim())
                .map(value => StringUtils.capitalize(value))
                .filter(value => value);

            let pattern = '';

            if (choices[0]?.startsWith('/')) {
                pattern = choices[0];
                choices = [];
            }

            questions.push({
                name,
                choices,
                pattern
            });

            console.log(name, choices);
        }

        this.event.questions = questions;
    }

    public async unsubscribeMember(member: Member): Promise<void> {

        const key = this.route.snapshot.paramMap.get('key') || '';

        if (!this.event.id || !key) {
            return;
        }

        const result = await this.overlayService.openModal({
            component: EditionUnsubscriptionModalComponent,
            inputs: {
                description: this.translateService.instant('cancel.title', { member })
            }
        });

        if (result === null) {
            return;
        }

        const request: UnsubscribeMemberRequest = {
            reason: result.reason,
            key
        };

        this.isLoading = true;

        this.onlineService
            .unsubscribeMember(this.event.id, member.id, request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: UnsubscribeMemberResponse) => {

                    this.event.members = response.members;
                },
                error: (response) => {
                    console.error(response.error);
                }
            });

    }

    public copyMembers(): void {

        const columnsSeparator = `	`;
        const linesSeparator = `\n`;

        let content = '';

        // Header
        let headers = [
            this.translateService.instant('inscription.pseudo.title'),
            this.translateService.instant('inscription.group.title'),
            this.translateService.instant('inscription.email.title'),
        ];

        if (this.event.isSimplifiedInscription === false) {
            headers = headers.concat([
                this.translateService.instant('inscription.firstName.title'),
                this.translateService.instant('inscription.lastName.title'),
                this.translateService.instant('inscription.phone.title'),
                this.translateService.instant('inscription.dob.title'),
            ])
        }

        for(const question of this.event.questions) {
            headers.push(question.name);
        }

        content += headers.join(columnsSeparator) + linesSeparator + linesSeparator;


        // Lines
        for(const member of this.event.members) {

            let line = [
                member.pseudo,
                member.group,
                member.email
            ];

            if (this.event.isSimplifiedInscription === false) {
                line = line.concat([
                    member.firstName || '',
                    member.lastName || '',
                    member.phone || '',
                    member.dob?.toString() || '',
                ])
            }

            if (member.responses) {
                for(const response of member.responses) {
                    line.push(response.value);
                }
            }

            content += line.join(columnsSeparator) + linesSeparator;
        }

        navigator.clipboard.writeText(content);

        this.overlayService.openNotification({
            message: this.translateService.instant('edition.notifications.membersCopied'),
            type: 'success'
        });
    }


    public async deleteEvent(): Promise<void> {

        const key: string = this.route.snapshot.paramMap.get('key') || '';

        if (!this.event.id || !key) {

            return;
        }

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: this.translateService.instant('edition.confirmations.delete.event')
            }
        }) !== true) {

            return;
        }

        this.isLoading = true;

        this.onlineService
            .deleteEvent(this.event.id, { key })
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: DeleteXEventResponse) => {

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.delete.success'),
                        type: 'error' //
                    });

                    this.router.navigateByUrl('/');
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.delete.error'),
                        type: 'error'
                    });
                }
            });
    }

    public view(): void {

        window.open(`/events/${this.event.id}?skip=true`);
    }

    public share(): void {

        navigator.clipboard.writeText(`${window.location.origin}/events/${this.event.id}${this.isUrlCopied ? '?skip=true' : ''}`);

        this.overlayService.openNotification({
            message: this.translateService.instant('edition.notifications.urlCopied'),
            type: 'success'
        });

        this.isUrlCopied = true;

        setTimeout(() => { this.isUrlCopied = false; }, 3000);
    }

    public save(): void {

        if(this.event.id) {

            this.update();

            return;
        }

        this.create();
    }

    private create(): void {

        // Create new
        const { id, members, key, ...required } = this.event;

        const createEventRequest: CreateXEventRequest = {
            ...required,
            image: this.newImage
        };

        this.isLoading = true;

        this.onlineService.createEvent(createEventRequest)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response: CreateXEventResponse) => {

                    const { key, ...eventData } = response;

                    this.event = Object.assign(this.event, eventData);

                    this.title.setTitle(this.event.name);
                    this.updatedImage = this.newImage;
                    this.newImage = undefined;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.creation.success'),
                        type: 'success'
                    });

                    this.router.navigate([`/events/${this.event.id}/edition/${key}`]);
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.creation.error'),
                        type: 'error'
                    });
                }
            });
    }

    private update(): void {

        const key: string = this.route.snapshot.paramMap.get('key') || '';

        // Don't update members list, useless and probably out to date
        const { id, members, ...data } = this.event;

        // Update existing
        const updateEventRequest: UpdateXEventRequest = {
            ...data,
            key,
            image: this.newImage
        };

        this.isLoading = true;

        this.onlineService
            .updateEvent(id, updateEventRequest)
            .pipe(finalize(() => { this.isLoading = false; })
            )
            .subscribe({
                next: (response: boolean) => {

                    console.log('updated', response);

                    this.title.setTitle(this.event.name);
                    this.updatedImage = this.newImage;
                    this.newImage = undefined;

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.save.success'),
                        type: 'success'
                    });
                },
                error: (response) => {

                    console.error(response.error);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('edition.notifications.save.error'),
                        type: 'error'
                    });
                }
            });

    }

}
